import SampleActions from '../../actions/schools/samples'
import createIndexReducer from '../helpers/indexReducer'

export default createIndexReducer({
  fetchAction:
    SampleActions.collectedSampleActions.collectionActions.fetchRequest,
  unloadAction: SampleActions.collectedSampleActions.collectionActions.unload,
  fetchSuccessAction:
    SampleActions.collectedSampleActions.collectionActions.fetchSuccess,
  fetchFailureAction:
    SampleActions.collectedSampleActions.collectionActions.fetchFailure,
  collectionName: 'collectedSchoolSamples'
})
