export const RoleType = {
  SystemAdmin: 'system-admins',
  AccountAdmin: 'account-admins',
  ReadOnlyAccountAdmin: 'read-only-account-admins',
  DistrictUser: 'district-users',
  FacilityUser: 'facility-users',
  FieldAdmin: 'field-admins',
  FieldUser: 'field-users'
}

export const RoleTypeLevels = {
  [RoleType.SystemAdmin]: 0,
  [RoleType.AccountAdmin]: 1,
  [RoleType.FieldAdmin]: 2,
  [RoleType.ReadOnlyAccountAdmin]: 3,
  [RoleType.DistrictUser]: 4,
  [RoleType.FacilityUser]: 5,
  [RoleType.FieldUser]: 6,
}

export const nonFieldUserRoles = [
  RoleType.SystemAdmin,
  RoleType.AccountAdmin,
  RoleType.ReadOnlyAccountAdmin,
  RoleType.FieldAdmin,
  RoleType.DistrictUser,
  RoleType.FacilityUser
]

export const allRoleTypes = Object.values(RoleType)
