import { WebNotification } from '@120wateraudit/envirio-components/dist/models'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getUnreadNotificationsFailure,
  getUnreadNotificationsRequest,
  getUnreadNotificationsSuccess
} from '../actions/unreadNotifications'
import { APIProvider } from '../api'

function* getUnreadNotificationsSaga() {
  try {
    const unreadNotifications: WebNotification[] = yield call(
      APIProvider.fetchNotifications
    )

    yield put(getUnreadNotificationsSuccess({ unreadNotifications }))
  } catch (error) {
    let message = error.message
    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message
    }
    yield put(getUnreadNotificationsFailure({ error: message }))
  }
}

export default function* userSaga() {
  yield takeLatest(
    getUnreadNotificationsRequest.toString(),
    getUnreadNotificationsSaga
  )
}
