import { handleActions } from 'redux-actions'

export interface IndexReducerState<T> {
  items: T[]
  isFetching: boolean
  errors: [any]
}

interface IndexReducerOptions {
  fetchAction: any
  fetchSuccessAction: any
  fetchFailureAction: any
  unloadAction?: any
  collectionName: string
  additionalActions?: object
}

const initialState = {
  items: [],
  isFetching: false,
  errors: [],
}

const createIndexReducer: any = ({
  fetchAction,
  fetchSuccessAction,
  fetchFailureAction,
  unloadAction,
  collectionName,
  additionalActions,
}: IndexReducerOptions) => {
  const fetchActionString = fetchAction.toString()
  const fetchSuccessActionString = fetchSuccessAction.toString()
  const fetchFailureActionString = fetchFailureAction.toString()
  const unloadActionString = unloadAction.toString()

  return handleActions<any>(
    {
      [fetchActionString]: (state: IndexReducerState<any>, action: any) => {
        return {
          ...state,
          items: [],
          isFetching: true,
        }
      },
      [fetchSuccessActionString]: (
        state: IndexReducerState<any>,
        action: any
      ) => {
        return {
          ...state,
          items: action.payload[collectionName],
          isFetching: false,
        }
      },
      [fetchFailureActionString]: (
        state: IndexReducerState<any>,
        action: any
      ) => {
        return {
          ...state,
          isFetching: false,
        }
      },
      [unloadActionString]: (state: IndexReducerState<any>, action: any) =>
        initialState,
    },
    initialState
  )
}

export default createIndexReducer
