import { SchoolSamplingEvent } from '@120wateraudit/envirio-components/dist/models'
import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

export const createSamplingEvent = action('CREATE_SAMPLE_EVENT_REQUEST')
export const createSamplingEventSuccess = action<{
  samplingEvent: SchoolSamplingEvent
}>('CREATE_SAMPLE_EVENT_SUCCESS')
export const createSamplingEventFailure = action<{ error: string }>(
  'CREATE_SAMPLE_EVENT_FAILURE'
)

export const updateSamplingEvent = action('UPDATE_SAMPLING_EVENT_REQUEST')
export const updateSamplingEventSuccess = action<{
  schoolSamplingEvent: SchoolSamplingEvent
}>('UPDATE_SAMPLING_EVENT_SUCCESS')
export const updateSamplingEventFailure = action<{ error: string }>(
  'UPDATE_SAMPLING_EVENT_FAILURE'
)

export const updateSamplingEventStatus = action(
  'UPDATE_SAMPLING_EVENT_STATUS_REQUEST'
)
export const updateSamplingEventStatusSuccess = action<{
  schoolSamplingEvent: SchoolSamplingEvent
}>('UPDATE_SAMPLING_EVENT_STATUS_SUCCESS')
export const updateSamplingEventStatusFailure = action<{ error: string }>(
  'UPDATE_SAMPLING_EVENT_STATUS_FAILURE'
)

export const updateSchoolSamplingEventStatus = action(
  'UPDATE_SCHOOL_SAMPLING_EVENT_STATUS_REQUEST'
)
export const updateSchoolSamplingEventStatusSuccess = action(
  'UPDATE_SCHOOL_SAMPLING_EVENT_STATUS_SUCCESS'
)
export const updateSchoolSamplingEventStatusFailure = action<{ error: string }>(
  'UPDATE_SCHOOL_SAMPLING_EVENT_STATUS_FAILURE'
)

export const fetchSamplingEvent = action('FETCH_SCHOOL_SAMPLING_EVENT')
export const fetchSamplingEventSuccess = action<{
  schoolSamplingEvent: SchoolSamplingEvent
}>('FETCH_SCHOOL_SAMPLING_EVENT_SUCCESS')
export const fetchSamplingEventFailure = action<{ error: string }>(
  'FETCH_SCHOOL_SAMPLING_EVENT_FAILURE'
)
export const unloadSamplingEvent = action('UNLOAD_SCHOOL_SAMPLING_EVENT')

export const fetchSchoolSamplingEvents = action('FETCH_SCHOOL_SAMPLING_EVENTS')
export const fetchSchoolSamplingEventsSuccess = action<{
  schoolSamplingEvents: SchoolSamplingEvent[]
}>('FETCH_SCHOOL_SAMPLING_EVENTS_SUCCESS')
export const fetchSchoolSamplingEventsFailure = action<{ error: string }>(
  'FETCH_SCHOOL_SAMPLING_EVENTS_FAILURE'
)
export const unloadSchoolSamplingEvents = action(
  'UNLOAD_SCHOOL_SAMPLING_EVENTS'
)

export const deleteSamplingEvent = action('DELETE_SAMPLING_EVENT_REQUEST')
export const deleteSamplingEventSuccess = action(
  'DELETE_SAMPLING_EVENT_SUCCESS'
)
export const deleteSamplingEventFailure = action<{ error: string }>(
  'DELETE_SAMPLING_EVENT_FAILURE'
)

export const unloadAccountSamplingEvents = action(
  'UNLOAD_ACCOUNT_SAMPLING_EVENTS'
)

export const setSelectedSamplingEvent = action('SET_SELECTED_SAMPLING_EVENT')
export const setSelectedSamplingEventStatus = action(
  'SET_SELECTED_SAMPLING_EVENT_STATUS'
)

export const releaseSampleResults = action<{
  accountId: number
  schoolId: number
  samplingEventId: number
}>('RELEASE_SAMPLE_RESULTS_REQUEST')
export const releaseSampleResultsSuccess = action(
  'RELEASE_SAMPLE_RESULTS_SUCCESS'
)
export const releaseSampleResultsFailure = action<{ error: string }>(
  'RELEASE_SAMPLE_RESULTS_FAILURE'
)
