import { WebNotification } from '@120wateraudit/envirio-components/dist/models'
import actionCreatorFactory from 'typescript-fsa'

import CrudActions from './helpers/CrudActions'

const action = actionCreatorFactory()

export default CrudActions<WebNotification>({
  entityName: 'notification',
  collectionName: 'notifications'
})

export const markNotificationRead = action<{
  payload: WebNotification
}>('MARK_NOTIFICATION_READ_REQUEST')

export const markNotificationReadSuccess = action<{
  notification: WebNotification
}>('MARK_NOTIFICATION_READ_SUCCESS')
export const markNotificationReadFailure = action<{ error: string }>(
  'MARK_NOTIFICATION_READ_FAILURE'
)
