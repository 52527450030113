import { fork } from 'redux-saga/effects'

// Sagas
import notifications from './notifications'
import collectedSamples from './schools/collectedSamples'
import fieldCollection from './schools/fieldCollection'
import schoolFixtures from './schools/fixtures'
import remediations from './schools/remediations'
import {
  MultiSampleCreationSaga,
  SamplesSaga as schoolSamples
} from './schools/samples'
import samplingEvents from './schools/samplingEvents'
import uncollectedSamples from './schools/uncollectedSamples'
import unreadNotifications from './unreadNotifications'
import user from './user'

export default function* rootSaga() {
  yield [
    fork(schoolFixtures),
    fork(schoolSamples),
    fork(MultiSampleCreationSaga),
    fork(samplingEvents),
    fork(notifications),
    fork(unreadNotifications),
    fork(user),
    fork(fieldCollection),
    fork(uncollectedSamples),
    fork(collectedSamples),
    fork(remediations)
  ]
}
