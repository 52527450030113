import SampleActions, {
  createSampleMulti,
  createSampleMultiFailure,
  createSampleMultiSuccess
} from '../../actions/schools/samples'
import createDetailsReducer from '../helpers/detailsReducer'

export default createDetailsReducer({
  // Create
  createActionRequest:
    SampleActions.schoolSampleActions.createActions.createRequest,
  createActionSuccess:
    SampleActions.schoolSampleActions.createActions.createSuccess,
  createActionFailure:
    SampleActions.schoolSampleActions.createActions.createFailure,

  // Fetch
  fetchAction: SampleActions.schoolSampleActions.detailsActions.fetchRequest,
  fetchSuccessAction:
    SampleActions.schoolSampleActions.detailsActions.fetchSuccess,
  fetchFailureAction:
    SampleActions.schoolSampleActions.detailsActions.fetchFailure,

  // Unload
  unloadAction: SampleActions.schoolSampleActions.detailsActions.unload,

  // Update
  updateActionRequest:
    SampleActions.schoolSampleActions.updateActions.updateRequest,
  updateActionSuccess:
    SampleActions.schoolSampleActions.updateActions.updateSuccess,
  updateActionFailure:
    SampleActions.schoolSampleActions.updateActions.updateFailure,

  // Delete
  deleteAction: SampleActions.schoolSampleActions.deleteActions.deleteRequest,
  deleteSuccessAction:
    SampleActions.schoolSampleActions.deleteActions.deleteSuccess,
  deleteFailureAction:
    SampleActions.schoolSampleActions.deleteActions.deleteFailure,

  // Additional Actions
  additionalActions: {
    [createSampleMulti.toString()]: (state: any, action: any) => ({
      ...state,
      isSaving: true
    }),
    [createSampleMultiSuccess.toString()]: (state: any, action: any) => ({
      ...state,
      isSaving: false
    }),
    [createSampleMultiFailure.toString()]: (state: any, action: any) => ({
      ...state,
      isSaving: false
    })
  },
  entityName: 'schoolSample'
})
