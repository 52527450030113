import { User } from '@120wateraudit/envirio-components/dist/models'
import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

export const loginRequest = action<{ token: string }>('LOGIN_REQUEST')
export const loginSuccess = action<{ user: User }>('LOGIN_SUCCESS')
export const loginFailure = action<{ error: string }>('LOGIN_FAILURE')
export const logout = action('LOGOUT')

export const updateProfileRequest = action<{
  firstName: string
  lastName: string
  currentPassword?: string
  password?: string
  passwordConfirmation?: string
  callback?: () => void
}>('UPDATE_PROFILE_REQUEST')
export const updateProfileSuccess = action<{ user: User }>(
  'UPDATE_PROFILE_SUCCESS'
)
export const updateProfileFailure = action<{ error: string }>(
  'UPDATE_PROFILE_FAILURE'
)

export const resetError = action<{}>('RESET_ERROR')
