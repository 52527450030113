import { RoleType } from './roleType'

export const MenuItems = {
  Districts: {
    name: 'Districts'
  },
  Facilities: {
    name: 'Facilities'
  },
  Plans: {
    name: 'Plans'
  },
  Collections: {
    name: 'Collections'
  },
  Reports: {
    name: 'Reports'
  }
}

const allMenuItems = [Object.values(MenuItems)]

export const MenuItemsForTypes = {
  [RoleType.SystemAdmin]: allMenuItems,
  [RoleType.DistrictUser]: [
    MenuItems.Districts,
    MenuItems.Facilities,
    MenuItems.Plans,
    MenuItems.Collections
  ],
  [RoleType.FacilityUser]: [
    MenuItems.Districts,
    MenuItems.Facilities,
    MenuItems.Plans,
    MenuItems.Collections
  ],
  [RoleType.AccountAdmin]: allMenuItems,
  [RoleType.ReadOnlyAccountAdmin]: [
    MenuItems.Districts,
    MenuItems.Facilities,
    MenuItems.Plans,
    MenuItems.Collections
  ],
  [RoleType.FieldAdmin]: allMenuItems,
  [RoleType.FieldUser]: [MenuItems.Collections]
}
