import { WebNotification } from '@120wateraudit/envirio-components/dist/models'
import actionCreatorFactory from 'typescript-fsa'

const action = actionCreatorFactory()

export const getUnreadNotificationsRequest = action(
  'GET_UNREAD_NOTIFICATIONS_REQUEST'
)

export const getUnreadNotificationsFailure = action<{ error: string }>(
  'GET_UNREAD_NOTIFICATIONS_FAILURE'
)

export const getUnreadNotificationsSuccess = action<{
  unreadNotifications: WebNotification[]
}>('GET_UNREAD_NOTIFICATIONS_SUCCESS')
