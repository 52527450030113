import {
  deleteSamplingEvent,
  deleteSamplingEventFailure,
  fetchSamplingEvent,
  fetchSamplingEventFailure,
  fetchSamplingEventSuccess,
  unloadSamplingEvent,
  updateSamplingEvent,
  updateSamplingEventFailure,
  updateSamplingEventSuccess
} from '../actions/schools/samplingEvents'
import createDetailsReducer from './helpers/detailsReducer'

export default createDetailsReducer({
  // Fetch
  fetchAction: fetchSamplingEvent,
  fetchSuccessAction: fetchSamplingEventSuccess,
  fetchFailureAction: fetchSamplingEventFailure,
  // Unload
  unloadAction: unloadSamplingEvent,
  // Update
  updateActionRequest: updateSamplingEvent,
  updateActionSuccess: updateSamplingEventSuccess,
  updateActionFailure: updateSamplingEventFailure,
  // Delete
  deleteAction: deleteSamplingEvent,
  deleteSuccessAction: deleteSamplingEvent,
  deleteFailureAction: deleteSamplingEventFailure,
  entityName: 'schoolSamplingEvent'
})
