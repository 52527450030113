import { Loader as EnvirioLoader } from '@120wateraudit/envirio-components'
import * as React from 'react'
import styled from 'src/theme'

const animationRadius = '22px'
const dotSize = '14px'

interface Props {
  pastDelay?: boolean
}

const Loader = ({ pastDelay }: Props) => {
  if (pastDelay === undefined) {
    return (
      <Wrapper>
        <EnvirioLoader />
      </Wrapper>
    )
  }
  if (typeof pastDelay === 'boolean' && pastDelay) {
    return (
      <Wrapper>
        <EnvirioLoader />
      </Wrapper>
    )
  } else {
    return null
  }
}

const Wrapper = styled.div`
  position: relative;
  width: 44px;
  height: ${dotSize};
  margin: ${animationRadius} auto;
`

export default Loader
