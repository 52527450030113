import {
  fetchSchoolSamplingEvents,
  fetchSchoolSamplingEventsFailure,
  fetchSchoolSamplingEventsSuccess,
  unloadSchoolSamplingEvents
} from '../../actions/schools/samplingEvents'
import createIndexReducer from '../helpers/indexReducer'

export default createIndexReducer({
  fetchAction: fetchSchoolSamplingEvents,
  unloadAction: unloadSchoolSamplingEvents,
  fetchSuccessAction: fetchSchoolSamplingEventsSuccess,
  fetchFailureAction: fetchSchoolSamplingEventsFailure,
  collectionName: 'schoolSamplingEvents'
})
