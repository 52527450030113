import { SchoolSample } from '@120wateraudit/envirio-components/dist/models'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  cancelSchoolSample,
  cancelSchoolSampleFailure,
  cancelSchoolSampleSuccess,
  collectSchoolSample,
  collectSchoolSampleFailure,
  collectSchoolSampleSuccess
} from '../../actions/schools/samples'
import { APIProvider } from '../../api'
import Logger from '../../utils/Logger'
import { pushRoute } from '../../utils/navigation'
import { NotificationType, notify } from '../../utils/notifications'

function* collectSchoolSampleSaga(action: any) {
  try {
    const { schoolId, sample } = action.payload
    const schoolSample: SchoolSample = yield ((call as unknown) as any)(
      APIProvider.updateSchoolSample,
      ...action.payload
    )
    yield put(collectSchoolSampleSuccess({ schoolSample }))
    yield call(
      pushRoute,
      `/fieldcollection/schools/${schoolId}/samplingevents/${
        sample.samplingEventId
      }?tab=3`
    )

    notify({
      text: `Sample collected.`,
      type: NotificationType.Success
    })
  } catch (error) {
    Logger.log(error)
    yield put(collectSchoolSampleFailure({ error }))
  }
}

function* cancelSchoolSampleSaga(action: any) {
  try {
    const { schoolId, sample } = action.payload
    const schoolSample: SchoolSample = yield ((call as unknown) as any)(
      APIProvider.updateSchoolSample,
      ...action.payload
    )
    yield put(cancelSchoolSampleSuccess({ schoolSample }))
    yield call(
      pushRoute,
      `/fieldcollection/schools/${schoolId}/samplingevents/${
        sample.samplingEventId
      }?tab=3`
    )

    notify({
      text: `Sample cancelled.`,
      type: NotificationType.Success
    })
  } catch (error) {
    Logger.log(error)
    yield put(cancelSchoolSampleFailure({ error }))
  }
}

export default function* FieldCollectionSaga() {
  yield takeLatest(collectSchoolSample.toString(), collectSchoolSampleSaga)
  yield takeLatest(cancelSchoolSample.toString(), cancelSchoolSampleSaga)
}
