// tslint:disable:no-console
import { Action, combineReducers } from 'redux'
import schoolDistrict from './district'
import collectedSchoolSamples from './schools/collectedSchoolSamples'
import schoolFixture from './schools/fixture'
import schoolFixtures from './schools/fixtures'
import schoolRemediation from './schools/remediation'
import schoolRemediations from './schools/remediations'
import schoolSample from './schools/sample'
import schoolSamples from './schools/samples'
import schoolSamplingEvents from './schools/schoolSamplingEvents'
import uncollectedSchoolSamples from './schools/uncollectedSamples'
import schoolSamplingEvent from './schoolSamplingEvent'

import { DetailsReducerState } from './helpers/detailsReducer'
import { IndexReducerState } from './helpers/indexReducer'
import unreadNotifications, {
  UnreadNotificationsState
} from './unreadNotifications'
import user, { UserState } from './user'

import {
  Remediation,
  School,
  SchoolDistrict,
  SchoolFixture,
  SchoolSample,
  SchoolSamplingEvent
} from '@120wateraudit/envirio-components/dist/models'
import { schoolsClient, customFieldsClient } from 'src/apollo-clients'
import { LocalStorageService } from 'src/utils/LocalStorageService'

export interface ApplicationState {
  user: UserState
  schoolDistricts: IndexReducerState<SchoolDistrict>
  schoolDistrict: DetailsReducerState
  school: DetailsReducerState
  schools: IndexReducerState<School>
  schoolFixture: DetailsReducerState
  schoolFixtures: IndexReducerState<SchoolFixture>
  schoolSample: DetailsReducerState
  schoolSamples: IndexReducerState<SchoolSample>
  uncollectedSchoolSamples: IndexReducerState<SchoolSample>
  collectedSchoolSamples: IndexReducerState<SchoolSample>
  schoolSamplingEvent: DetailsReducerState
  unreadNotifications: UnreadNotificationsState
  schoolRemediations: IndexReducerState<Remediation>
  schoolRemediation: DetailsReducerState
  schoolSamplingEvents: IndexReducerState<SchoolSamplingEvent>
}

const appReducer = combineReducers({
  user,
  schoolDistrict,
  schoolFixture,
  schoolFixtures,
  schoolRemediation,
  schoolRemediations,
  schoolSample,
  schoolSamples,
  uncollectedSchoolSamples,
  collectedSchoolSamples,
  schoolSamplingEvent,
  unreadNotifications,
  schoolSamplingEvents
})

const rootReducer = (state: ApplicationState, action: Action) => {
  // Nuke app state on logout
  if (action.type === 'LOGOUT') {
    const storage = new LocalStorageService()
    storage.removeToken()
    storage.removeUser()
    schoolsClient.clearStore()
    customFieldsClient.clearStore()
    return undefined
  }

  return appReducer(state, action)
}

export default rootReducer
