import * as React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { browserHistory } from '../../utils/navigation'
import * as Async from './AsyncContainers'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { connect } from 'react-redux'
import { ApplicationState } from 'src/reducers'
import { getUser } from 'src/selectors/user'
import { User } from '@120wateraudit/envirio-components/dist/models'
import AuthenticatedApp from 'src/router/AuthenticatedApp'
import { Dashboard } from './AsyncContainers'

interface Props {
  user: User
}

const App = AuthenticatedApp(Dashboard)
class Routes extends React.Component<Props> {
  render() {
    const { user } = this.props
    return (
      <>
        <Router history={browserHistory}>
          <Switch>
            <Route
              exact
              path="/login"
              render={props => <Async.Login {...props} />}
            />
            <App user={user}>
              <AuthenticatedRoute
                path="/"
                render={({ location: { pathname } }) => (
                  <Async.Dashboard pathname={pathname} user={user} />
                )}
              />
            </App>
          </Switch>
        </Router>
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  // Only add application-wide required props here!
  user: getUser(state)
})

export default connect(mapStateToProps)(Routes as any)
