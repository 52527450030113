// Planning
// tslint:disable-next-line:max-classes-per-file
export class SamplePlanningRoute {
  static getSamplingEventDetailsRoute(
    schoolId: number,
    samplingEventId: number
  ): string {
    return `/plans/schools/${schoolId}/samplingevents/${samplingEventId}`
  }
}

// Facility Management
// tslint:disable-next-line:max-classes-per-file
export class FacilityManagementRoutes {
  static getSchoolDetailsRoute(
    schoolId: number,
    queryString: string = ''
  ): string {
    return `/schools/schools/${schoolId}${queryString}`
  }

  static getDistrictDetailsRoute(districtId: number): string {
    return `'/schools/districts/${districtId}`
  }

  static getCreateFixtureRoute(schoolId: number): string {
    return `/schools/schools/${schoolId}/fixture/create`
  }

  static getFixtureDetailsRoute(schoolId: number, fixtureId: number): string {
    return `/schools/schools/${schoolId}/fixtures/${fixtureId}`
  }

  static getCreateSampleRoute(
    schoolId: number,
    queryString: string = ''
  ): string {
    return `/schools/schools/${schoolId}/sample/create${queryString}`
  }

  static getSampleDetailsRoute(schoolId: number, sampleId: number): string {
    return `/schools/schools/${schoolId}/samples/${sampleId}`
  }

  static getCreateRemediationRoute(schoolId: number, sampleId: number): string {
    return `/schools/schools/${schoolId}/samples/${sampleId}/remediation/create`
  }

  static getRemediationDetailsRoute(
    schoolId: number,
    remediationId: number
  ): string {
    return `/schools/schools/${schoolId}/remediations/${remediationId}`
  }

  static getCreateSurveyRoute(schoolId: number): string {
    return `/schools/school/${schoolId}/surveys/create`
  }

  static getSurveyDetailsRoute(schoolId: number, surveyId: number): string {
    return `/schools/school/${schoolId}/surveys/${surveyId}`
  }
}

// Field Collection
// tslint:disable-next-line:max-classes-per-file
export class CollectionRoutes {
  static getSamplingEventDetailsRoute(
    schoolId: number,
    samplingEventId: number,
    queryString: string = ''
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}${queryString}`
  }

  static getSampleDetailsRoute(
    schoolId: number,
    samplingEventId: number,
    sampleId: number
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/samples/${sampleId}`
  }

  static getCreateSampleRoute(
    schoolId: number,
    samplingEventId: number,
    queryString: string = ''
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/sample/create${queryString}`
  }

  static getCollectSampleRoute(
    schoolId: number,
    samplingEventId: number,
    sampleId: number
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/collect/${sampleId}`
  }

  static getCreateFixtureRoute(
    schoolId: number,
    samplingEventId: number
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/fixture/create`
  }

  static getFixtureDetailsRoute(
    schoolId: number,
    samplingEventId: number,
    fixtureId: number
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/fixtures/${fixtureId}`
  }

  static getCreateSurveyRoute(
    schoolId: number,
    samplingEventId: number
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/survey/create`
  }

  static getSurveyDetailsRoute(
    schoolId: number,
    samplingEventId: number,
    surveyId: number
  ): string {
    return `/collections/schools/${schoolId}/samplingevents/${samplingEventId}/fieldinfo/${surveyId}`
  }
}

export default {
  Admin: {
    manageFields: '/admin/customfields/manage',
    manageFieldsDetails: '/admin/customfields/manage/:placementName',
    managePTDContent: '/admin/ptd/manage-content'
  },
  Collections: {
    // Collection
    collectSample:
      '/collections/schools/:id/samplingevents/:samplingEventId/collect/:sampleId',
    // Fixtures
    createFixture:
      '/collections/schools/:id/samplingevents/:samplingEventId/fixture/create',
    createSample:
      '/collections/schools/:id/samplingevents/:samplingEventId/sample/create',
    // Surveys
    createSurvey:
      '/collections/schools/:id/samplingevents/:samplingEventId/survey/create',
    fixtureDetails:
      '/collections/schools/:id/samplingevents/:samplingEventId/fixtures/:fixtureId',
    index: '/collections',
    // Samples
    sampleDetails:
      '/collections/schools/:id/samplingevents/:samplingEventId/samples/:sampleId',
    samplingEventDetails:
      '/collections/schools/:id/samplingevents/:samplingEventId',
    surveyDetails:
      '/collections/schools/:id/samplingevents/:samplingEventId/surveys/:surveyId'
  },
  Districts: {
    details: '/districts/district/:id',
    index: '/districts'
  },
  Notifications: {
    index: '/notifications'
  },
  Planning: {
    createSamplingEvent: '/plans/create',
    index: '/plans',
    samplingEventDetails: '/plans/schools/:schoolId/samplingevents/:id'
  },
  ProgramHub: {
    index: '/programhub'
  },
  Reports: {
    index: '/reports'
  },
  Root: '/',
  Schools: {
    // Fixtures
    createFixture: '/schools/school/:id/fixture/create',
    // Samples
    createSample: '/schools/school/:id/sample/create',
    // Surveys
    createSurvey: '/schools/school/:schoolId/surveys/create',
    districtDetails: '/schools/districts/:id',
    fixtureDetails: '/schools/school/:id/fixtures/:fixtureId',
    index: '/schools',
    // Remediations
    // createRemediation:
    //   '/schools/school/:id/samples/:sampleId/remediation/create',
    remediationDetails: '/schools/school/:id/remediations/:remediationId',
    sampleDetails: '/schools/school/:id/samples/:sampleId',
    schoolDetails: '/schools/school/:id',
    surveyDetails: '/schools/school/:schoolId/surveys/:surveyId'
  }
}
