import { call, fork, put, takeLatest } from 'redux-saga/effects'
import CrudSaga from './helpers/CrudSaga'

// API
import { APIProvider } from '../api'

// Utils
import Logger from '../utils/Logger'

// Models
import { WebNotification } from '@120wateraudit/envirio-components/dist/models'

// Actions
import NotificationActions, {
  markNotificationRead,
  markNotificationReadFailure,
  markNotificationReadSuccess
} from '../actions/notifications'

interface MarkAsReadOptions {
  payload: WebNotification
}

function* markNotificationReadSaga({
  payload: notification
}: MarkAsReadOptions) {
  try {
    yield call(APIProvider.markNotificationRead, notification)
    yield put(markNotificationReadSuccess({ notification }))
    yield put(NotificationActions.collectionActions.fetchRequest())
  } catch (error) {
    Logger.log(error)
    yield put(markNotificationReadFailure({ error }))
  }
}

function* notificationsSaga() {
  yield ((takeLatest as unknown) as any)(
    markNotificationRead.toString(),
    markNotificationReadSaga
  )
}

export default function* Sagas() {
  yield [
    // CRUD
    fork(
      CrudSaga({
        entityName: 'notification',
        collectionName: 'notifications',
        actions: NotificationActions,
        fetchCollectionMethod: APIProvider.fetchNotifications
      })
    ),
    // Additional Sagas
    fork(notificationsSaga)
  ]
}
