const Noty = require('noty')
import { SchoolsCustomFieldEntity } from '@120wateraudit/envirio-components/dist/models'
export const NotificationType = {
  Warning: 'warning',
  Alert: 'alert',
  Success: 'success',
  Error: 'error',
  Info: 'info'
}

const defaultOptions = {
  type: NotificationType.Info,
  timeout: 3000,
  // killer: true,
  layout: 'topCenter',
  progressBar: false,
  theme: 'relax',
  closeWith: ['click', 'button']
}

export const notify = (options: any) => {
  return new Noty({ ...defaultOptions, ...options }).show()
}

export const displayCustomFieldSuccess = (field: string, entity: string) => {
  const friendlyEntityName = getFriendlyCustomFieldEntity(entity)
  const message = `Successfully created new ${field} field for ${friendlyEntityName}.`
  return notify({
    text: message,
    type: 'success'
  })
}

const getFriendlyCustomFieldEntity = (text: any) => {
  type SchoolsCustomFieldEntityKeys = keyof typeof SchoolsCustomFieldEntity

  return new Map<SchoolsCustomFieldEntity, SchoolsCustomFieldEntityKeys>(
    Object.entries(
      SchoolsCustomFieldEntity
    ).map(
      ([key, value]: [
        SchoolsCustomFieldEntityKeys,
        SchoolsCustomFieldEntity
      ]) => [value, key]
    )
  ).get(text)
}
