import { Role, User } from '@120wateraudit/envirio-components/dist/models'
import { uniq, flattenDeep } from 'lodash'

import { AuthorizedActionsForTypes } from './authorizedActions'
import { MenuItemsForTypes } from './menuItems'
import { RoleType, RoleTypeLevels } from './roleType'

export { AuthorizedActions } from './authorizedActions'
export { MenuItems, MenuItemsForTypes } from './menuItems'
export { RoleType, allRoleTypes, nonFieldUserRoles } from './roleType'

export class RoleManager {
  public static getMenuItemsForRole(roles: Role[]): Array<{ name: string }> {
    return uniq(
      flattenDeep(roles.map((r: Role) => {
        if (MenuItemsForTypes[r.name]) {
          return MenuItemsForTypes[r.name]
        }

        return []
      }) as any)
    ) as Array<{ name: string }>
  }

  public static hasRole(user: User, role: string): boolean {
    if (!role || !user || !user.roles || user.roles.length === 0) {
      return false
    }

    if (user.roles.some(r => r.name === role)) {
      return true
    }

    return false
  }

  public static hasAnyRole(user: User, roles: string[]): boolean {
    return roles.some(role => this.hasRole(user, role))
  }

  public static hasAllRoles(user: User, roles: string[]): boolean {
    return roles.every(role => this.hasRole(user, role))
  }

  public static isAdmin(user: User): boolean {
    return (
      RoleManager.hasRole(user, RoleType.SystemAdmin) ||
      RoleManager.hasRole(user, RoleType.AccountAdmin) ||
      RoleManager.hasRole(user, RoleType.FieldAdmin)
    )
  }

  public static isAccountAdmin(user: User): boolean {
    return RoleManager.hasRole(user, RoleType.AccountAdmin)
  }

  public static hasAuthorizedAction(user: User, action: string): boolean {
    if (!action || !user || !user.roles || user.roles.length === 0) {
      return false
    }

    return user.roles.some((r: Role) => {
      const authorizedActions = AuthorizedActionsForTypes[r.name] || []
      return !!authorizedActions.find(aa => aa === action)
    })
  }

  static getHighestRole(roles: Role[]): Role {
    const filteredRoles = roles.filter(role => {
      if (!role.name) {
        return false
      }
      return !isNaN(RoleTypeLevels[role.name])
    })
    return filteredRoles.reduce((acc, current) => {
      const level1 = RoleTypeLevels[acc.name]
      const level2 = RoleTypeLevels[current.name]
      if (level1 < level2) {
        return acc
      } else {
        return current
      }
    })
  }
}

export default RoleManager
