import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as FileSaver from 'file-saver'
import { pushRoute } from './utils/navigation'
import { NotificationType, notify } from './utils/notifications'
import { getToken } from './utils/token'

// Models
import {
  AccountType,
  Fixture,
  Remediation,
  SamplingEventStatus,
  SchoolFixture,
  SchoolSample,
  SchoolSamplingEvent,
  WebNotification
} from '@120wateraudit/envirio-components/dist/models'

// Use proxy for local dev, use ENV var from build otherwise
export const AWS_BASE = process.env.REACT_APP_AWS_ENDPOINT || ''

const CLIENT = axios.create({
  baseURL: AWS_BASE,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
})

interface Headers {
  Authorization: string
}

interface Credentials {
  headers: Headers
}

const redirectErrorStatuses = [401, 403]

export const getDefaultHeaders = () => {
  const token = getToken()
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getGridFetchOptions = (): AxiosRequestConfig => {
  return getDefaultHeaders()
}

export const ShowInvalidPermissionsWarning = () => {
  notify({
    killer: true,
    text:
      'Invalid user permissions. Please contact <a href="mailto:feedback@120wateraudit.com" target=_"blank">support</a> for assistance.',
    timeout: false,
    type: NotificationType.Error
  })
}

enum HttpStatus {
  Unauthorized = 401,
  Forbidden = 403
}

const APIResponseSuccessHandler = (response: AxiosResponse) => response.data
const APIResponseErrorHandler = (error: AxiosError) => {
  if (
    error.response &&
    redirectErrorStatuses.indexOf(error.response.status) > -1
  ) {
    if (error && error.response) {
      if (error.response.status === HttpStatus.Forbidden) {
        ShowInvalidPermissionsWarning()
      } else if (error.response.status === HttpStatus.Unauthorized) {
        setTimeout(() => {
          pushRoute('/login')
        }, 1000)
      }
    }
  } else {
    throw error
  }
}

export class APIProvider {
  public static getAPICredentials(): Credentials {
    return {
      ...getDefaultHeaders()
    }
  }

  public static fetch(url: string) {
    return CLIENT.get(url, APIProvider.getAPICredentials())
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler)
  }

  public static fetchCSV(url: string) {
    return CLIENT.get(url, {
      headers: {
        ...APIProvider.getAPICredentials().headers,
        ['Accept']: 'text/csv'
      }
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler)
  }

  public static fetchExcel(url: string) {
    return CLIENT.get(url, {
      headers: {
        ...APIProvider.getAPICredentials().headers,
        ['Accept']:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer'
    })
      .then((response: AxiosResponse) => ({
        data: response.data,
        headers: response.headers
      }))
      .catch(APIResponseErrorHandler)
  }

  public static downloadDocument = (url: string, fileName: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const response = await CLIENT.get(url, {
          headers: {
            ...getDefaultHeaders().headers,
            Accept: 'application/octet-stream'
          },
          responseType: 'blob'
        })

        FileSaver.saveAs(response.data, fileName)
        resolve()
      } catch (error) {
        reject(APIResponseErrorHandler(error))
      }
    })
  }

  public static postExcel(url: string, data: any) {
    return CLIENT.post(url, data, {
      headers: {
        ...APIProvider.getAPICredentials().headers,
        ['Accept']:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer'
    })
      .then((response: AxiosResponse) => ({
        data: response.data,
        headers: response.headers
      }))
      .catch(APIResponseErrorHandler)
  }

  public static post(url: string, data: any, config?: any) {
    return CLIENT.post(url, data, {
      ...APIProvider.getAPICredentials(),
      ...config
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler)
  }

  public static put(url: string, data: any = {}) {
    return CLIENT.put(url, data, {
      method: 'PUT',
      ...APIProvider.getAPICredentials()
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler)
  }

  public static delete(url: string) {
    return CLIENT.delete(url, {
      method: 'DELETE',
      ...APIProvider.getAPICredentials()
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler)
  }

  // Users
  public static getCurrentUser = () => {
    return APIProvider.fetch(
      `/platform/account-management/rest/currentuser/@me`
    )
  }

  public static getCurrentUsersAccounts = () => {
    return APIProvider.fetch(
      `/platform/account-management/rest/useraccounts/@me?type=${AccountType.Schools}`
    )
  }

  public static updateUserProfile = (payload: {
    firstName: string
    lastName: string
  }) => {
    return APIProvider.put(
      `/platform/account-management/rest/currentuser/profile`,
      payload
    )
  }

  public static updateUserPassword = (payload: {
    currentPassword: string
    password: string
    passwordConfirm: string
  }) => {
    return APIProvider.put(
      `/platform/account-management/rest/currentuser/password`,
      payload
    )
  }

  public static fetchNotifications(): any {
    return APIProvider.fetch(`/facilities/schools/notifications/unread`)
  }

  public static fetchSchools({ accountId }: { accountId: number }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools`
    )
  }

  public static fetchSchoolFixtures({
    accountId,
    schoolId
  }: {
    accountId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/fixtures`
    )
  }

  public static fetchSchoolFixture({
    accountId,
    fixtureId,
    schoolId
  }: {
    accountId: number
    fixtureId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/fixtures/${fixtureId}`
    )
  }

  public static updateSchoolFixture({
    accountId,
    fixture,
    schoolId
  }: {
    accountId: number
    fixture: SchoolFixture
    schoolId: number
  }): any {
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/fixtures/${fixture.id}`,
      fixture
    )
  }

  public static createFixture({
    accountId,
    fixture,
    schoolId
  }: {
    accountId: number
    fixture: Fixture
    schoolId: number
  }): any {
    return APIProvider.post(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/fixtures`,
      fixture
    )
  }

  public static deleteFixture({
    accountId,
    fixtureId,
    schoolId
  }: {
    accountId: number
    fixtureId: number
    schoolId: number
  }): any {
    return APIProvider.delete(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/fixtures/${fixtureId}`
    )
  }

  public static deleteSample({
    accountId,
    sampleId,
    schoolId
  }: {
    accountId: number
    sampleId: number
    schoolId: number
  }): any {
    return APIProvider.delete(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/${sampleId}`
    )
  }

  public static fetchSchoolSamplingEvents({
    accountId,
    schoolId
  }: {
    accountId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingEvents`
    )
  }

  public static fetchSchoolSamples({
    accountId,
    schoolId
  }: {
    accountId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples`
    )
  }

  public static fetchUncollectedSchoolSamples(
    accountId: number,
    schoolId: number
  ): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/uncollected`
    )
  }

  public static fetchCollectedSchoolSamples(
    accountId: number,
    schoolId: number
  ): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/collected`
    )
  }

  public static fetchSchoolSample({
    accountId,
    sampleId,
    schoolId
  }: {
    accountId: number
    sampleId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/${sampleId}`
    )
  }

  public static updateSchoolSample({
    accountId,
    sample,
    schoolId
  }: {
    accountId: number
    sample: SchoolSample
    schoolId: number
  }): any {
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/${sample.id}`,
      sample
    )
  }

  public static updateSchoolSampleAdmin({
    accountId,
    sample,
    schoolId
  }: {
    accountId: number
    sample: SchoolSample
    schoolId: number
  }): any {
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/${sample.id}/admin`,
      sample
    )
  }

  public static createSchoolSample(
    accountId: number,
    schoolId: number,
    sample: SchoolSample
  ): any {
    return APIProvider.post(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples`,
      sample
    )
  }

  public static createBulkSchoolSamples({
    accountId,
    fixtureId,
    samples,
    samplingEventId,
    schoolId
  }: {
    accountId: number
    fixtureId: number
    samples: [SchoolSample]
    samplingEventId: number
    schoolId: number
  }): any {
    return APIProvider.post(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samples/bulk`,
      {
        fixtureId,
        samples,
        samplingEventId,
        schoolId
      }
    )
  }

  public static updateSchoolSamplingEvent(
    accountId: number,
    schoolId: number,
    samplingEvent: SchoolSamplingEvent
  ): any {
    // tslint:disable-next-line:max-line-length
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents/${samplingEvent.id}`,
      samplingEvent
    )
  }

  // tslint:disable-next-line:max-line-length
  public static updateSchoolSamplingEventStatus(
    accountId: number,
    schoolId: number,
    samplingEventId: number,
    status: SamplingEventStatus,
    designCompletedNotes: string,
    collectionCompletedNotes: string
  ): any {
    // tslint:disable-next-line:max-line-length
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents/${samplingEventId}/status`,
      { collectionCompletedNotes, designCompletedNotes, status }
    )
  }

  public static deleteSchoolSamplingEvent(
    accountId: number,
    schoolId: number,
    samplingEventId: number
  ): any {
    return APIProvider.delete(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents/${samplingEventId}`
    )
  }

  public static fetchSchoolSchoolSamplingEvent(
    accountId: number,
    schoolId: number,
    id: number
  ): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents/${id}`
    )
  }

  public static createSamplingEvent(
    accountId: number,
    schoolId: number,
    sample: SchoolSample
  ): any {
    return APIProvider.post(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents`,
      sample
    )
  }

  public static bulkAssociateSamplesWithEvent(
    accountId: number,
    schoolId: number,
    sampleId: number,
    ids: [number]
  ): any {
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents/${sampleId}/samples`,
      { ids }
    )
  }

  public static markNotificationRead(notification: WebNotification): any {
    return APIProvider.put(
      `/facilities/schools/notifications/${notification.id}`,
      {
        isRead: true
      }
    )
  }

  public static fetchSchoolRemediations({
    accountId,
    schoolId
  }: {
    accountId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/remediations`
    )
  }

  public static fetchSchoolRemediation({
    accountId,
    remediationId,
    schoolId
  }: {
    accountId: number
    remediationId: number
    schoolId: number
  }): any {
    return APIProvider.fetch(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/remediations/${remediationId}`
    )
  }

  public static createSchoolRemediation({
    accountId,
    remediation,
    schoolId
  }: {
    accountId: number
    remediation: Remediation
    schoolId: number
  }): any {
    return APIProvider.post(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/remediations/`,
      remediation
    )
  }

  public static updateSchoolRemediation({
    accountId,
    remediation,
    schoolId
  }: {
    accountId: number
    remediation: Remediation
    schoolId: number
  }): any {
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/remediations/${remediation.id}`,
      remediation
    )
  }

  public static releaseSampleResults({
    accountId,
    samplingEventId,
    schoolId
  }: {
    accountId: number
    samplingEventId: number
    schoolId: number
  }): any {
    return APIProvider.put(
      `/facilities/schools/accounts/${accountId}/schools/${schoolId}/samplingevents/${samplingEventId}/release`,
      {
        isReleased: true
      }
    )
  }

  public static savePTDContent({
    accountId,
    data
  }: SavePTDContentArg): Promise<SavePTDContentResponse> {
    const endpoint = `/facilities/rest/accounts/${accountId}/ptd/save`
    return APIProvider.post(endpoint, data)
  }
}

interface SavePTDContentArg {
  accountId: number
  data: FormData
}

interface SavePTDContentResponse {
  errors: string[]
}
