import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, RouteProps, useHistory } from 'react-router-dom'
import { User } from '@120wateraudit/envirio-components/dist/models'
import Logger from 'src/utils/Logger'

export default (Component: C) => (props: Props): JSX.Element | null => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    history.listen(location => Logger.trackPageView(location.pathname))
  }, [history])

  const redirectRoute = `/login?redirect=${props.location!.pathname}${
    props.location!.search
  }`

  useEffect(() => {
    setLoading(prev => !prev)
  }, [])

  if (loading) {
    return null
  }

  return (
    (props.user && <Component {...props} />) || <Redirect to={redirectRoute} />
  )
}

interface Props extends RouteProps {
  user?: User
}

type C = (props: Props) => JSX.Element
