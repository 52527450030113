import SampleActions from '../../actions/schools/samples'
import createIndexReducer from '../helpers/indexReducer'

export default createIndexReducer({
  fetchAction:
    SampleActions.uncollectedSampleActions.collectionActions.fetchRequest,
  unloadAction: SampleActions.uncollectedSampleActions.collectionActions.unload,
  fetchSuccessAction:
    SampleActions.uncollectedSampleActions.collectionActions.fetchSuccess,
  fetchFailureAction:
    SampleActions.uncollectedSampleActions.collectionActions.fetchFailure,
  collectionName: 'uncollectedSchoolSamples'
})
