import DistrictActions from '../actions/schoolDistricts/index'
import createDetailsReducer from './helpers/detailsReducer'

export default createDetailsReducer({
  // Fetch
  fetchAction: DistrictActions.detailsActions.fetchRequest,
  fetchSuccessAction: DistrictActions.detailsActions.fetchSuccess,
  fetchFailureAction: DistrictActions.detailsActions.fetchFailure,
  // Update
  updateActionRequest: DistrictActions.updateActions.updateRequest,
  updateActionSuccess: DistrictActions.updateActions.updateSuccess,
  updateActionFailure: DistrictActions.updateActions.updateFailure,
  // Delete
  deleteAction: DistrictActions.deleteActions.deleteRequest,
  deleteSuccessAction: DistrictActions.deleteActions.deleteSuccess,
  deleteFailureAction: DistrictActions.deleteActions.deleteFailure,
  // Unload
  unloadAction: DistrictActions.detailsActions.unload,
  entityName: 'schoolDistrict'
})
