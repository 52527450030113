import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

import { SchoolDistrict } from '@120wateraudit/envirio-components/dist/models'
import CrudActions, { ICrudActions } from '../helpers/CrudActions'

const actions: ICrudActions = CrudActions<SchoolDistrict>({
  entityName: 'schoolDistrict',
  collectionName: 'schoolDistricts'
})

export default actions
