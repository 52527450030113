import { RoleType } from './roleType'

export const AuthorizedActions = {
  CreateBuildings: 'create-buildings',
  CreateFixtures: 'create-fixtures',
  CreateRemediations: 'create-remediations',
  CreateSamples: 'create-samples',
  CreateSamplingEvents: 'create-sampling-events',
  CreateSchoolDistricts: 'create-school-districts',
  CreateSchools: 'create-schools',
  DeleteFixtures: 'delete-fixtures',
  DeleteRemediations: 'delete-remediations',
  DeleteSamplingEvents: 'delete-sampling-events',
  EditSchoolDistrict: 'edit-school-district',
  EditSchools: 'edit-schools',
  ExportFixtures: 'export-fixtures',
  ExportSamples: 'export-samples',
  ViewSchool: 'view-school',
  ReleaseResults: 'release-results',
  UpdateBuildings: 'update-buildings',
  UpdateFixtures: 'update-fixtures',
  UpdateRemediations: 'update-remediations',
  UpdateSamples: 'update-samples',
  UpdateSamplingEvents: 'update-sampling-events',
  ViewBuildings: 'view-buildings',
  ViewDashboard: 'view-dashboard',
  ViewFieldSchedulingReport: 'view-field-scheduling-report',
  ViewSampleResults: 'view-sample-results',
  ViewSamplingCollectionActions: 'view-sampling-collection-actions',
  ViewSamplingPlanActions: 'view-sampling-plan-actions',
  ViewSdwisReport: 'view-sdwis-report',
  ViewWeeklyInternalReport: 'view-weekly-internal-report',
  CreateDocuments: 'create-documents',
  DeleteDocuments: 'delete-documents',
  CreateSchoolDistrictUsers: 'create-school-district-users',
  DeleteSchoolDistrictUsers: 'delete-school-district-users',
  CreateSchoolUsers: 'create-school-users',
  DeleteSchoolUsers: 'delete-school-users',
  CreateSamplingEventUsers: 'create-sampling-event-users',
  DeleteSamplingEventUsers: 'delete-sampling-event-users',
  UpdateSurveys: 'update-surveys',
  CreateSurveys: 'create-surveys'
}

const AA = AuthorizedActions

export const AuthorizedActionsForTypes = {
  [RoleType.SystemAdmin]: Object.values(AA),
  [RoleType.AccountAdmin]: Object.values(AA),
  [RoleType.ReadOnlyAccountAdmin]: [
    AA.ViewDashboard,
    AA.ViewBuildings,
    AA.ExportFixtures,
    AA.ViewSampleResults,
    AA.ExportSamples,
    AA.ViewSchool
  ],
  [RoleType.DistrictUser]: [
    AA.ViewDashboard,
    AA.EditSchoolDistrict,
    AA.ViewBuildings,
    AA.EditSchools,
    AA.ExportFixtures,
    AA.CreateFixtures,
    AA.CreateSamples,
    AA.DeleteFixtures,
    AA.UpdateFixtures,
    AA.UpdateSamples,
    AA.CreateBuildings,
    AA.UpdateBuildings,
    AA.ViewSampleResults,
    AA.ExportSamples,
    AA.UpdateRemediations,
    AA.DeleteRemediations,
    AA.CreateSamplingEvents,
    AA.UpdateSamplingEvents,
    AA.ViewSamplingPlanActions,
    AA.DeleteSamplingEvents,
    AA.ReleaseResults,
    AA.ViewSamplingCollectionActions,
    AA.CreateRemediations,
    AA.CreateDocuments,
    AA.DeleteDocuments,
    AA.ViewSchool,
    AA.CreateSchoolDistrictUsers,
    AA.DeleteSchoolDistrictUsers,
    AA.CreateSchoolUsers,
    AA.DeleteSchoolUsers,
    AA.CreateSamplingEventUsers,
    AA.DeleteSamplingEventUsers,
    AA.UpdateSurveys,
    AA.CreateSurveys
  ],
  [RoleType.FacilityUser]: [
    AA.ViewDashboard,
    AA.ViewBuildings,
    AA.EditSchools,
    AA.ExportFixtures,
    AA.CreateFixtures,
    AA.CreateSamples,
    AA.DeleteFixtures,
    AA.UpdateFixtures,
    AA.UpdateSamples,
    AA.CreateBuildings,
    AA.UpdateBuildings,
    AA.ViewSampleResults,
    AA.ExportSamples,
    AA.UpdateRemediations,
    AA.DeleteRemediations,
    AA.CreateSamplingEvents,
    AA.UpdateSamplingEvents,
    AA.ViewSamplingPlanActions,
    AA.DeleteSamplingEvents,
    AA.ReleaseResults,
    AA.ViewSamplingCollectionActions,
    AA.CreateRemediations,
    AA.CreateDocuments,
    AA.DeleteDocuments,
    AA.ViewSchool,
    AA.CreateSchoolUsers,
    AA.DeleteSchoolUsers,
    AA.CreateSamplingEventUsers,
    AA.DeleteSamplingEventUsers,
    AA.UpdateSurveys,
    AA.CreateSurveys
  ],
  [RoleType.FieldAdmin]: [
    AA.ViewDashboard,
    AA.EditSchoolDistrict,
    AA.EditSchools,
    AA.CreateFixtures,
    AA.CreateSamples,
    AA.DeleteFixtures,
    AA.UpdateFixtures,
    AA.UpdateSamples,
    AA.CreateSamplingEvents,
    AA.UpdateSamplingEvents,
    AA.DeleteSamplingEvents,
    AA.ViewSamplingCollectionActions,
    AA.ViewFieldSchedulingReport,
    AA.CreateDocuments,
    AA.DeleteDocuments,
    AA.ViewSchool,
    AA.CreateSchoolDistrictUsers,
    AA.DeleteSchoolDistrictUsers,
    AA.CreateSchoolUsers,
    AA.DeleteSchoolUsers,
    AA.CreateSamplingEventUsers,
    AA.DeleteSamplingEventUsers,
    AA.UpdateSurveys,
    AA.CreateSurveys
  ],
  [RoleType.FieldUser]: [
    AA.CreateFixtures,
    AA.CreateSamples,
    AA.DeleteFixtures,
    AA.UpdateFixtures,
    AA.UpdateSamples,
    AA.UpdateSamplingEvents,
    AA.ViewSamplingCollectionActions,
    AA.CreateDocuments,
    AA.DeleteDocuments,
    AA.UpdateSurveys,
    AA.CreateSurveys
  ]
}
