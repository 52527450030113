const ReactLoadable = require('react-loadable')
import Loader from '../../components/Common/Loader/index'

interface Options {
  loader(): Promise<any>
  delay?: number
  [key: string]: any
}

export default function Loadable(opts: Options) {
  return ReactLoadable({
    loading: Loader,
    delay: 200,
    timeout: 10,
    ...opts
  })
}
