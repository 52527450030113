import Loadable from '../../containers/hoc/Loadable'

export const Login = Loadable({
  delay: 240,
  loader: () => import('src/mobile-app/containers/Login/index')
})

export const Dashboard = Loadable({
  loader: () => import('src/mobile-app/containers/Dashboard/index')
})

export const Profile = Loadable({
  loader: () => import('src/mobile-app/containers/Profile/index')
})

export const ProgramHub = Loadable({
  loader: () => import('src/mobile-app/containers/ProgramHub/index')
})

export const Schools = Loadable({
  loader: () => import('src/mobile-app/containers/Schools/index')
})

export const School = Loadable({
  loader: () => import('src/mobile-app/containers/School/index')
})

export const SamplingEvents = Loadable({
  loader: () => import('src/mobile-app/containers/SamplingEvents/index')
})

export const SamplingEvent = Loadable({
  loader: () => import('src/mobile-app/containers/SamplingEvent/index')
})

export const SamplingEventDetails = Loadable({
  loader: () => import('src/mobile-app/containers/SamplingEventDetails/index')
})

export const SamplingEventFixtures = Loadable({
  loader: () => import('src/mobile-app/containers/SamplingEventFixtures/index')
})

export const SamplingEventSamples = Loadable({
  loader: () => import('src/mobile-app/containers/SamplingEventSamples/index')
})

export const CreateFixture = Loadable({
  loader: () => import('src/mobile-app/containers/CreateFixture/index')
})

export const CreateFixtureSuccess = Loadable({
  loader: () => import('src/mobile-app/containers/CreateFixtureSuccess/index')
})

export const SchoolFixtures = Loadable({
  loader: () => import('src/mobile-app/containers/SchoolFixtures/index')
})

export const SchoolSamples = Loadable({
  loader: () => import('src/mobile-app/containers/SchoolSamples/index')
})

export const CreateSample = Loadable({
  loader: () => import('src/mobile-app/containers/CreateSample/index')
})

export const SchoolDetails = Loadable({
  loader: () => import('src/mobile-app/containers/SchoolDetails/index')
})

export const CollectSample = Loadable({
  loader: () => import('src/mobile-app/containers/CollectSample/index')
})

export const CreatePlan = Loadable({
  loader: () => import('src/mobile-app/containers/CreatePlan/index')
})
