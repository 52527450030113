// Helpers
import CrudSaga from '../helpers/CrudSaga'

// Actions
import FixtureActions from '../../actions/schools/fixtures'

// Models
import { SchoolFixture } from '@120wateraudit/envirio-components/dist/models'

// API
import { APIProvider } from '../../api'

export default CrudSaga<SchoolFixture>({
  entityName: 'schoolFixture',
  collectionName: 'schoolFixtures',
  actions: FixtureActions,
  createEntityMethod: APIProvider.createFixture,
  createSuccessMessage: (fixture: SchoolFixture) => `Fixture created.`,
  fetchEntityMethod: APIProvider.fetchSchoolFixture,
  updateEntityMethod: APIProvider.updateSchoolFixture,
  updateSuccessfulMessage: (fixture: SchoolFixture) =>
    `Fixture ${fixture.friendlyCode} updated successfully.`,
  deleteEntityMethod: APIProvider.deleteFixture,
  deleteSuccessMessage: () => `Fixture delete successfully.`,
  fetchCollectionMethod: APIProvider.fetchSchoolFixtures
})
