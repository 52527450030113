import { applyMiddleware, compose, createStore, Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { __DEV__ } from '../constants'
import reducer from '../reducers/index'
import sagas from '../sagas/index'

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()
const middlewares: Middleware[] = [sagaMiddleware]

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
)

sagaMiddleware.run(sagas)

if (__DEV__) {
  middlewares.push(logger)

  // For access to redux store in Cypress.
  const win = window as any
  if (win.Cypress) {
    win.store = store
  }
}
