import { DataProxy } from 'apollo-cache'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient, { Resolvers } from 'apollo-client'
import { Operation, split } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { setContext } from 'apollo-link-context'
import { ErrorResponse, onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { createUploadLink as UploadLink } from 'apollo-upload-client'
import { AWS_BASE } from 'src/api'
import { __DEV__ } from 'src/constants'
import { getToken } from 'src/utils/token'
import { pushRoute } from './utils/navigation'
import * as requestSanitizers from './utils/requestSanitizers'

class SanitizedBatchLink extends BatchHttpLink {
  request(operation: Operation) {
    const sanitizer = requestSanitizers[operation.operationName]
    if (typeof sanitizer === 'function') {
      operation.variables = sanitizer(operation.variables)
    }
    return super.request(operation)
  }
}

export const schoolsClient = createClient({
  initialCacheData: {
    quickActionsPanelOpen: true
  },
  uri: `${AWS_BASE}/facilities/graphql`
})

export const contactsClient = createClient({
  name: 'contactsClient',
  uri: `${AWS_BASE}/platform/communications`
})

export const customFieldsClient = createClient({
  name: 'customFieldsClient',
  uri: `${AWS_BASE}/platform/custom-fields/graphql`
})

interface CreateClientArg {
  initialCacheData?: DataProxy.WriteDataOptions<any>['data']
  name?: string
  resolvers?: Resolvers
  uri: string
}

function createClient({
  initialCacheData = {},
  name,
  resolvers = {},
  uri
}: CreateClientArg): ApolloClient<NormalizedCacheObject> {
  const isFile = value =>
    (File !== undefined && value instanceof File) ||
    (Blob !== undefined && value instanceof Blob)

  const isUpload = ({ variables }) => Object.values(variables).some(isFile)
  const uploadLink = new UploadLink({ uri })
  const httpLink = new HttpLink({ uri })
  const batchedLink = new SanitizedBatchLink({ uri })

  const authLink = setContext((_, { headers }) => {
    const token = getToken()
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const errorLink: any = onError(
    ({ graphQLErrors, networkError }: ErrorResponse): void => {
      if (networkError || !graphQLErrors) {
        return
      }
      graphQLErrors.forEach(({ statusCode }: { statusCode: number }) => {
        if (!statusCode) {
          return
        }
        if (statusCode === 401) {
          pushRoute('/login')
        }
      })
    }
  )

  const cache = new InMemoryCache()

  cache.writeData({
    data: initialCacheData
  })

  return new ApolloClient({
    cache,
    connectToDevTools: __DEV__,
    link: errorLink.concat(
      authLink.concat(
        split(
          isUpload,
          uploadLink,
          split(
            operation => operation.getContext().disableBatching,
            httpLink as any,
            batchedLink
          )
        )
      )
    ),
    name,
    resolvers
  })
}
