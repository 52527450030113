import { eachObjectNode } from './eachObjectNode'

export const trimAllObjectStrings = (variables: object): object =>
  eachObjectNode(variables, trimmer)

function trimmer(_key: string, value: any) {
  if (typeof value === 'string')  {
    return value.trim()
  }
  return value
}
