import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ICrudActions } from '../../actions/helpers/CrudActions'
import Logger from '../../utils/Logger'
import { pushRoute } from '../../utils/navigation'
import { NotificationType, notify } from '../../utils/notifications'

interface CrudSagaOptions<T> {
  entityName: string
  collectionName: string
  actions: ICrudActions
  createEntityMethod?: any
  createSuccessMessage?: (createdItem: T) => string
  fetchEntityMethod?: any
  updateEntityMethod?: any
  updateSuccessfulMessage?: (updatedItem: T) => string
  updateTakeEvery?: boolean
  deleteEntityMethod?: any
  deleteSuccessMessage?: () => string
  fetchCollectionMethod?: any
}

export default function GenerateCrudSaga<T>({
  entityName,
  collectionName,
  actions,
  createEntityMethod,
  createSuccessMessage,
  fetchEntityMethod,
  updateEntityMethod,
  updateSuccessfulMessage,
  updateTakeEvery,
  deleteEntityMethod,
  deleteSuccessMessage,
  fetchCollectionMethod
}: CrudSagaOptions<T>) {
  function* fetchDetailsSaga(action: any) {
    try {
      const item: T = yield ((call as unknown) as any)(
        fetchEntityMethod,
        ...action.payload
      )
      yield put(actions.detailsActions.fetchSuccess({ [entityName]: item }))
    } catch (error) {
      Logger.log(error)
      yield put(actions.detailsActions.fetchFailure({ error }))
    }
  }

  function* fetchCollectionSaga(action: any) {
    try {
      const items: T[] = yield ((call as unknown) as any)(
        fetchCollectionMethod,
        ...action.payload
      )
      yield put(
        actions.collectionActions.fetchSuccess({ [collectionName]: items })
      )
    } catch (error) {
      Logger.log(error)
      yield put(actions.collectionActions.fetchFailure({ error }))
    }
  }

  function* updateSaga(action: any) {
    try {
      const item: T = yield ((call as unknown) as any)(
        updateEntityMethod,
        ...action.payload
      )
      const { redirect } = action.payload

      yield put(actions.updateActions.updateSuccess({ [entityName]: item }))

      if (redirect) {
        pushRoute(redirect)
      }

      if (updateSuccessfulMessage) {
        notify({
          text: updateSuccessfulMessage(item),
          type: NotificationType.Success
        })
      }
    } catch (error) {
      Logger.log(error)
      yield put(actions.updateActions.updateFailure({ error }))
    }
  }

  function* deleteSaga(action: any) {
    try {
      const { redirect } = action.payload

      yield ((call as unknown) as any)(deleteEntityMethod, ...action.payload)
      yield put(actions.deleteActions.deleteSuccess())

      if (redirect) {
        pushRoute(redirect)
      }

      if (deleteSuccessMessage) {
        notify({
          text: deleteSuccessMessage(),
          type: NotificationType.Success
        })
      }
    } catch (error) {
      Logger.log(error)
      yield put(actions.deleteActions.deleteFailure({ error }))

      if (error && error.response && error.response.data) {
        notify({
          text: error.response.data,
          type: NotificationType.Error
        })
      }
    }
  }

  function* createSaga(action: any) {
    try {
      const { redirect, postCreateAction } = action.payload
      const item: T = yield ((call as unknown) as any)(
        createEntityMethod,
        ...action.payload
      )
      yield put(actions.createActions.createSuccess({ [entityName]: item }))

      if (redirect) {
        pushRoute(redirect)
      }

      if (createSuccessMessage) {
        notify({
          text: createSuccessMessage(item),
          type: NotificationType.Success
        })
      }

      if (postCreateAction) {
        yield put(postCreateAction)
      }
    } catch (error) {
      Logger.log(error)
      yield put(actions.createActions.createFailure({ error }))

      if (error && error.response && error.response.data) {
        notify({
          text: error.response.data,
          type: NotificationType.Error
        })
      }
    }
  }

  return function* crudSaga() {
    if (fetchEntityMethod) {
      yield takeLatest(
        actions.detailsActions.fetchRequest.toString(),
        fetchDetailsSaga
      )
    }

    if (updateEntityMethod) {
      if (updateTakeEvery) {
        yield takeEvery(
          actions.updateActions.updateRequest.toString(),
          updateSaga
        )
      } else {
        yield takeLatest(
          actions.updateActions.updateRequest.toString(),
          updateSaga
        )
      }
    }

    if (deleteEntityMethod) {
      yield takeLatest(
        actions.deleteActions.deleteRequest.toString(),
        deleteSaga
      )
    }

    if (fetchCollectionMethod) {
      yield takeLatest(
        actions.collectionActions.fetchRequest.toString(),
        fetchCollectionSaga
      )
    }

    if (createEntityMethod) {
      yield takeLatest(
        actions.createActions.createRequest.toString(),
        createSaga
      )
    }
  }
}
