import SampleActions from '../../actions/schools/samples'
import createIndexReducer from '../helpers/indexReducer'

export default createIndexReducer({
  fetchAction: SampleActions.schoolSampleActions.collectionActions.fetchRequest,
  unloadAction: SampleActions.schoolSampleActions.collectionActions.unload,
  fetchSuccessAction:
    SampleActions.schoolSampleActions.collectionActions.fetchSuccess,
  fetchFailureAction:
    SampleActions.schoolSampleActions.collectionActions.fetchFailure,
  collectionName: 'schoolSamples'
})
