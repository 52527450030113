import Loadable from 'src/containers/hoc/Loadable'

export const AsyncApp = Loadable({
  loader: () => import('src/containers/App')
})

export const AsyncProgramHub = Loadable({
  loader: () => import('src/containers/ProgramHub/index')
})

export const AsyncLogin = Loadable({
  loader: () => import('src/containers/Login')
})

export const AsyncNotifications = Loadable({
  loader: () => import('src/containers/Notifications/index')
})

export const AsyncPlanning = Loadable({
  loader: () => import('src/containers/Plans/index')
})

export const AsyncSchoolSamplingEvent = Loadable({
  loader: () => import('src/containers/Plan/index')
})

export const AsyncSchools = Loadable({
  loader: () => import('src/containers/Schools')
})

export const AsyncDistricts = Loadable({
  loader: () => import('src/containers/Districts/index')
})

export const AsyncCollections = Loadable({
  loader: () => import('src/containers/Collections')
})

export const AsyncFieldInfo = Loadable({
  loader: () => import('src/containers/Collections/FieldInfo')
})

export const AsyncDistrict = Loadable({
  loader: () => import('src/containers/District/index')
})

export const AsyncReporting = Loadable({
  loader: () => import('src/containers/Reporting/index')
})

export const AsyncSchool = Loadable({
  loader: () => import('src/containers/School/index')
})

export const AsyncManageFields = Loadable({
  loader: () => import('src/containers/CustomFields/Manage')
})

export const AsyncManageFieldDetails = Loadable({
  loader: () => import('src/containers/CustomFields/Manage/details')
})

export const AsyncManagePTDContent = Loadable({
  loader: () => import('src/containers/PTD/ManageContent')
})
