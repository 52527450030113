import * as styledComponents from 'styled-components'

const { ThemeProvider, css, default: styled, keyframes } = styledComponents

export default styled
export { ThemeProvider, css, keyframes }

export const colorPalette = {
  circle: {
    blue: '#E5F8FC',
    green: '#E5FAF3',
    orange: 'rgba(251, 118, 0, 0.1)',
    red: 'rgba(239,9,0,0.1)',
    yellow: 'rgba(244,178,62, 0.1)'
  },
  icon: {
    blue: '#03B5E1',
    green: '#00CD87',
    orange: '#fb7600',
    red: 'rgba(239,9,0,1)',
    yellow: 'rgba(244,178,62, 1)'
  }
}
