import {
  SampleDrawType,
  SchoolSample
} from '@120wateraudit/envirio-components/dist/models'
import { call, put, takeLatest } from 'redux-saga/effects'
import SampleActions, {
  createSampleMulti,
  createSampleMultiSuccess,
  updateSampleAdmin,
  updateSampleAdminFailure,
  updateSampleAdminSuccess
} from '../../actions/schools/samples'
import { APIProvider } from '../../api'
import Logger from '../../utils/Logger'
import { pushRoute } from '../../utils/navigation'
import { NotificationType, notify } from '../../utils/notifications'

// Helpers
import CrudSaga from '../helpers/CrudSaga'

interface CreateSampleMultiOptions {
  payload: {
    accountId: number
    schoolId: number
    sample: SchoolSample
    drawTypes: [SampleDrawType]
    redirect?: string
  }
}

interface UpdateSampleAdminOptions {
  payload: {
    accountId: number
    schoolId: number
    sample: SchoolSample
    redirect?: string
    callback?: () => any
  }
}

function* createSchoolSampleMultiSaga({
  payload: { accountId, schoolId, sample, drawTypes, redirect }
}: CreateSampleMultiOptions) {
  try {
    const samples = drawTypes.map((dt: SampleDrawType) => ({
      ...sample,
      sampleDrawType: dt
    }))

    yield call(APIProvider.createBulkSchoolSamples, {
      accountId,
      schoolId,
      fixtureId: sample.fixtureId,
      samplingEventId: sample.samplingEventId,
      samples
    })

    if (redirect) {
      yield call(pushRoute, redirect)
    }

    notify({
      text: `Samples created.`,
      type: NotificationType.Success
    })

    yield put(createSampleMultiSuccess({ schoolSample: sample }))
  } catch (error) {
    Logger.log(error)
    yield put(
      SampleActions.schoolSampleActions.createActions.createFailure({ error })
    )
  }
}

function* updateSampleAdminSaga({
  payload: { accountId, schoolId, sample, redirect, callback }
}: UpdateSampleAdminOptions) {
  try {
    yield call(APIProvider.updateSchoolSampleAdmin, {
      accountId,
      schoolId,
      sample
    })

    if (redirect) {
      yield call(pushRoute, redirect)
    }

    if (typeof callback === 'function') {
      callback()
    }

    notify({
      text: `Sample updated.`,
      type: NotificationType.Success
    })

    yield put(updateSampleAdminSuccess())
  } catch (error) {
    Logger.log(error)
    yield put(updateSampleAdminFailure())
  }
}

export function* MultiSampleCreationSaga() {
  yield ((takeLatest as unknown) as any)(
    createSampleMulti.toString(),
    createSchoolSampleMultiSaga
  )
  yield ((takeLatest as unknown) as any)(
    updateSampleAdmin.toString(),
    updateSampleAdminSaga
  )
}

export const SamplesSaga = CrudSaga({
  entityName: 'schoolSample',
  collectionName: 'schoolSamples',
  actions: SampleActions.schoolSampleActions,
  createEntityMethod: APIProvider.createSchoolSample,
  createSuccessMessage: (sample: SchoolSample) => `Sample created.`,
  fetchEntityMethod: APIProvider.fetchSchoolSample,
  updateEntityMethod: APIProvider.updateSchoolSample,
  updateSuccessfulMessage: (sample: SchoolSample) =>
    `Sample updated successfully`,
  fetchCollectionMethod: APIProvider.fetchSchoolSamples
})
