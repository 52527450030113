import * as React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { LocalStorageService } from '../../utils/LocalStorageService'
import Logger from '../../utils/Logger'

export class AuthenticatedRoute extends Route<RouteProps> {
  render() {
    const storage = new LocalStorageService()
    let lsUser = storage.getUser()
    if (lsUser && typeof lsUser === 'string') {
      try {
        lsUser = JSON.parse(lsUser)
      } catch (error) {
        Logger.log(error)
      }
    }
    if (!lsUser) {
      return <Redirect to="/login" />
    }
    return super.render()
  }
}
