import { User } from '@120wateraudit/envirio-components/dist/models'
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  logout,
  resetError,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure
} from '../actions/user'

const initialState = {
  isLoading: false,
  error: null,
  user: null,
  token: null
}

export interface UserState {
  isLoading: boolean
  error: string | null
  user: User | null
  token: string | null
}

const reducer = reducerWithInitialState<UserState>(initialState)
  .case(loginRequest, state => ({
    ...state,
    isLoading: true
  }))
  .case(loginSuccess, (state, { user }: { user: User }) => ({
    ...state,
    isLoading: false,
    user
  }))
  .case(loginFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  }))
  .case(logout, state => ({
    ...state,
    user: null
  }))
  .case(updateProfileRequest, state => ({
    ...state,
    isLoading: true
  }))
  .case(updateProfileSuccess, (state, { user }: { user: User }) => ({
    ...state,
    isLoading: false,
    user
  }))
  .case(updateProfileFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  }))
  .case(resetError, state => ({
    ...state,
    error: null
  }))

export default reducer
