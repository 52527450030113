import RemediationActions from '../../actions/schools/remediations'
import createDetailsReducer from '../helpers/detailsReducer'

export default createDetailsReducer({
  fetchAction: RemediationActions.detailsActions.fetchRequest,
  // Unload
  unloadAction: RemediationActions.detailsActions.unload,
  // Fetch
  fetchSuccessAction: RemediationActions.detailsActions.fetchSuccess,
  fetchFailureAction: RemediationActions.detailsActions.fetchFailure,
  // Update
  updateActionRequest: RemediationActions.updateActions.updateRequest,
  updateActionSuccess: RemediationActions.updateActions.updateSuccess,
  updateActionFailure: RemediationActions.updateActions.updateFailure,
  // Delete
  deleteAction: RemediationActions.deleteActions.deleteRequest,
  deleteSuccessAction: RemediationActions.deleteActions.deleteSuccess,
  deleteFailureAction: RemediationActions.deleteActions.deleteFailure,
  entityName: 'schoolRemediation'
})
