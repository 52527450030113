import { createBrowserHistory } from 'history'

export const browserHistory = Object.assign(createBrowserHistory(), {
  getSearchObject: (searchString: string) => {
    try {
      const s = searchString.substring(1)
      return JSON.parse(
        '{"' +
          decodeURI(s)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    } catch (e) {
      return {}
    }
  }
})

export const pushRoute = (route: string, state?: any) =>
  browserHistory.push(route, state)
export const replaceRoute = (route: string) => browserHistory.replace(route)
export const goBack = () => browserHistory.goBack()
