import { ApplicationState } from '../reducers/index'

export const getIsLoading = (state: ApplicationState) =>
  state && state.user ? state.user.isLoading : undefined

export const getToken = (state: ApplicationState) =>
  state && state.user ? state.user.token : undefined

export const getUser = (state: ApplicationState) =>
  state && state.user && state.user.user ? state.user.user : undefined

export const getError = (state: ApplicationState) =>
  state && state.user ? state.user.error : undefined
