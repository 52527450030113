import { SchoolAccount } from '@120wateraudit/envirio-components/dist/models'
import { ApplicationState } from 'src/reducers'

export const getDefaultAccount = (
  state: ApplicationState
): SchoolAccount | undefined => {
  if (state && state.user.user && state.user.user.defaultAccount) {
    return (state.user.user.defaultAccount as unknown) as SchoolAccount
  }
  return undefined
}

export const getDefaultAccountId = (state: ApplicationState) => {
  if (state && state.user.user && state.user.user.defaultAccount) {
    return state.user.user.defaultAccount.id
  }
  return undefined
}
