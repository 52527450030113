import { WebNotification } from '@120wateraudit/envirio-components/dist/models'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { markNotificationReadSuccess } from '../actions/notifications'
import {
  getUnreadNotificationsFailure,
  getUnreadNotificationsRequest,
  getUnreadNotificationsSuccess
} from '../actions/unreadNotifications'

const initialState = {
  items: [],
  isLoading: false,
  error: ''
}

export interface UnreadNotificationsState {
  items: WebNotification[]
  isLoading: boolean
  error: string
}

const reducer = reducerWithInitialState<UnreadNotificationsState>(initialState)
  .case(getUnreadNotificationsRequest, state => ({
    ...state,
    isLoading: true,
    error: ''
  }))
  .case(getUnreadNotificationsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  }))
  .case(getUnreadNotificationsSuccess, (state, { unreadNotifications }) => ({
    ...state,
    items: unreadNotifications,
    isLoading: false,
    error: ''
  }))
  .case(markNotificationReadSuccess, (state, { notification }) => {
    if (state && state.items) {
      state.items.splice(state.items.indexOf(notification), 1)
    }
    return {
      ...state,
      isLoading: false,
      error: ''
    }
  })

export default reducer
