import { SchoolSample } from '@120wateraudit/envirio-components/dist/models'
import actionFactory from 'typescript-fsa'
import CrudActions, { ICrudActions } from '../helpers/CrudActions'

const actionCreator = actionFactory()

const actions: {
  schoolSampleActions: ICrudActions
  uncollectedSampleActions: ICrudActions
  collectedSampleActions: ICrudActions
} = {
  schoolSampleActions: CrudActions<SchoolSample>({
    entityName: 'schoolSample',
    collectionName: 'schoolSamples'
  }),
  uncollectedSampleActions: CrudActions<SchoolSample>({
    collectionName: 'uncollectedSamples'
  }),
  collectedSampleActions: CrudActions<SchoolSample>({
    collectionName: 'collectedSamples'
  })
}

export default actions

export const collectSchoolSample = actionCreator(
  'COLLECT_SCHOOL_SAMPLE_REQUEST'
)
export const collectSchoolSampleSuccess = actionCreator<{
  schoolSample: SchoolSample
}>('COLLECT_SCHOOL_SAMPLE_SUCCESS')
export const collectSchoolSampleFailure = actionCreator<{ error: string }>(
  'COLLECT_SCHOOL_SAMPLE_FAILURE'
)

export const cancelSchoolSample = actionCreator('CANCEL_SCHOOL_SAMPLE_REQUEST')
export const cancelSchoolSampleSuccess = actionCreator<{
  schoolSample: SchoolSample
}>('CANCEL_SCHOOL_SAMPLE_SUCCESS')
export const cancelSchoolSampleFailure = actionCreator<{ error: string }>(
  'CANCEL_SCHOOL_SAMPLE_FAILURE'
)

export const createSampleMulti = actionCreator('CREATE_SAMPLE_MULTI_REQUEST')
export const createSampleMultiSuccess = actionCreator<{
  schoolSample: SchoolSample
}>('CREATE_SAMPLE_MULTI_SUCCESS')
export const createSampleMultiFailure = actionCreator<{ error: string }>(
  'CREATE_SAMPLE_MULTI_FAILURE'
)

export const updateSampleAdmin = actionCreator('UPDATE_SAMPLE_ADMIN')
export const updateSampleAdminSuccess = actionCreator(
  'UPDATE_SAMPLE_ADMIN_SUCCESS'
)
export const updateSampleAdminFailure = actionCreator(
  'UPDATE_SAMPLE_ADMIN_FAILURE'
)
