import FixtureActions from '../../actions/schools/fixtures'
import createIndexReducer from '../helpers/indexReducer'

export default createIndexReducer({
  fetchAction: FixtureActions.collectionActions.fetchRequest,
  unloadAction: FixtureActions.collectionActions.unload,
  fetchSuccessAction: FixtureActions.collectionActions.fetchSuccess,
  fetchFailureAction: FixtureActions.collectionActions.fetchFailure,
  collectionName: 'schoolFixtures'
})
