import FixtureActions from '../../actions/schools/fixtures'
import detailsReducer from '../helpers/detailsReducer'

export default detailsReducer({
  // Create
  createActionRequest: FixtureActions.createActions.createRequest,
  createActionSuccess: FixtureActions.createActions.createSuccess,
  createActionFailure: FixtureActions.createActions.createFailure,
  // Read
  fetchAction: FixtureActions.detailsActions.fetchRequest,
  fetchSuccessAction: FixtureActions.detailsActions.fetchSuccess,
  fetchFailureAction: FixtureActions.detailsActions.fetchFailure,
  // Update
  updateActionRequest: FixtureActions.updateActions.updateRequest,
  updateActionSuccess: FixtureActions.updateActions.updateSuccess,
  updateActionFailure: FixtureActions.updateActions.updateFailure,
  // Delete
  deleteAction: FixtureActions.deleteActions.deleteRequest,
  deleteSuccessAction: FixtureActions.deleteActions.deleteSuccess,
  deleteFailureAction: FixtureActions.deleteActions.deleteFailure,
  // Unload
  unloadAction: FixtureActions.detailsActions.unload,
  entityName: 'schoolFixture'
})
