const raygun = require('raygun4js')

import { __DEV__, RAYGUN_API_KEY } from '../constants'
import { StaticImplements } from '../types/StaticImplements'

interface SetUserOptions {
  authId: string
  email: string
  firstName: string
  fullName: string
}

interface ErrorLogger {
  init(): void
  setUser(s: SetUserOptions): void
  log(e: Error): void
  trackPageView(path: string): void
}

@StaticImplements<ErrorLogger>()
class Logger {
  public static init() {
    raygun('apiKey', RAYGUN_API_KEY)
    raygun('enableCrashReporting', true)
    raygun('enablePulse', true)
    raygun('withTags', [window.location.host])
  }

  public static setUser({
    authId,
    email,
    firstName,
    fullName
  }: SetUserOptions) {
    raygun('setUser', {
      identifier: authId,
      isAnonymous: false,
      email,
      firstName,
      fullName
    })
  }

  public static log(error: Error) {
    raygun('send', error)
  }

  public static trackPageView(path: string) {
    raygun('trackEvent', { type: 'pageView', path })
  }
}

@StaticImplements<ErrorLogger>()
class DevLogger {
  public static init(): void {
    /* VOID */
  }
  public static setUser(_: SetUserOptions): void {
    /* VOID */
  }
  public static log(e: Error): void {
    console.error(e) // tslint:disable-line
  }
  public static trackPageView(path: string): void {
    console.log(`Navigated to path: ${path}`) // tslint:disable-line
  }
}

export default __DEV__ ? DevLogger : Logger
