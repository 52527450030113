// Helpers
import CrudSaga from '../helpers/CrudSaga'

// Actions
import RemediationActions from '../../actions/schools/remediations'

// Models
import { Remediation } from '@120wateraudit/envirio-components/dist/models'

// API
import { APIProvider } from '../../api'

export default CrudSaga<Remediation>({
  entityName: 'schoolRemediation',
  collectionName: 'schoolRemediations',
  actions: RemediationActions,
  createEntityMethod: APIProvider.createSchoolRemediation,
  createSuccessMessage: (_: Remediation) => `Remediation created.`,
  fetchEntityMethod: APIProvider.fetchSchoolRemediation,
  updateEntityMethod: APIProvider.updateSchoolRemediation,
  updateSuccessfulMessage: (_: Remediation) =>
    `Remediation updated successfully.`,
  fetchCollectionMethod: APIProvider.fetchSchoolRemediations
})
