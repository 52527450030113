// Actions
import SampleActions from '../../actions/schools/samples'

// API
import { APIProvider } from '../../api'

// Models
import { SchoolSample } from '@120wateraudit/envirio-components/dist/models'

// Helpers
import CrudSaga from '../helpers/CrudSaga'

export default CrudSaga<SchoolSample>({
  entityName: 'uncollectedSample',
  collectionName: 'uncollectedSamples',
  actions: SampleActions.uncollectedSampleActions,
  fetchCollectionMethod: APIProvider.fetchUncollectedSchoolSamples
})
