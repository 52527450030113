import RemediationActions from '../../actions/schools/remediations'
import createIndexReducer from '../helpers/indexReducer'

export default createIndexReducer({
  fetchAction: RemediationActions.collectionActions.fetchRequest,
  unloadAction: RemediationActions.collectionActions.unload,
  fetchSuccessAction: RemediationActions.collectionActions.fetchSuccess,
  fetchFailureAction: RemediationActions.collectionActions.fetchFailure,
  collectionName: 'schoolRemediations'
})
