import { LocalStorageService } from './LocalStorageService'

export const getToken = () => {
  if (window && window.localStorage) {
    const storage = new LocalStorageService()
    const t = storage.getToken()

    if (t) {
      return JSON.parse(t)
    }
  }

  return null
}
