import {
  SamplingEventStatus,
  SchoolSample,
  SchoolSamplingEvent,
  SystemUser
} from '@120wateraudit/envirio-components/dist/models'
import { omit } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createSamplingEvent,
  createSamplingEventFailure,
  createSamplingEventSuccess,
  deleteSamplingEvent,
  deleteSamplingEventFailure,
  deleteSamplingEventSuccess,
  fetchSamplingEventFailure,
  fetchSamplingEvent,
  fetchSamplingEventSuccess,
  fetchSchoolSamplingEvents,
  fetchSchoolSamplingEventsFailure,
  fetchSchoolSamplingEventsSuccess,
  releaseSampleResults,
  updateSamplingEvent,
  updateSamplingEventFailure,
  updateSamplingEventSuccess,
  updateSchoolSamplingEventStatus,
  updateSchoolSamplingEventStatusFailure
} from 'src/actions/schools/samplingEvents'
import { APIProvider } from 'src/api'
import Logger from '../../utils/Logger'
import { pushRoute } from 'src/utils/navigation'
import { NotificationType, notify } from 'src/utils/notifications'

interface CreateSampleOptions {
  payload: {
    accountId: number
    sampleEvent: SchoolSamplingEvent
    schoolId: number
    users: SystemUser[]
  }
}

interface FetchSchoolSamplingEventOptions {
  payload: {
    accountId: number
    id: number
    schoolId: number
  }
}

interface UpdateSamplingEventOptions {
  payload: {
    accountId: number
    disableNotification: boolean
    redirect: string
    samplingEvent: SchoolSamplingEvent
    schoolId: number
  }
}

interface DeleteSamplingEventOptions {
  payload: {
    accountId: number
    samplingEventId: number
    schoolId: number
  }
}

interface UpdateSamplingEventStatusOptions {
  payload: {
    accountId: number
    collectionCompletedNotes: string
    designCompletedNotes: string
    samplingEventId: number
    schoolId: number
    status: SamplingEventStatus
  }
}

function* createSamplingEventSaga({
  payload: { accountId, sampleEvent, schoolId, users }
}: CreateSampleOptions) {
  try {
    const samplingEvent: SchoolSamplingEvent = yield ((call as unknown) as any)(
      APIProvider.createSamplingEvent,
      accountId,
      schoolId,
      // TODO - Remove this.
      omit(sampleEvent, ['samples', 'school'])
    )

    if (users && samplingEvent.id) {
      yield Promise.all(
        users.map((u: SystemUser) => {
          return APIProvider.put(
            `/platform/account-management/rest/accounts/${accountId}/samplingevents/${samplingEvent.id}/users/${u.id}/add`,
            {}
          )
        })
      )
    }

    if (sampleEvent.samples && sampleEvent.samples.length > 0) {
      const sampleIds = sampleEvent.samples.map((s: SchoolSample) => s.id)
      yield call(
        APIProvider.bulkAssociateSamplesWithEvent,
        accountId,
        schoolId,
        samplingEvent.id,
        sampleIds
      )
    }

    yield put(createSamplingEventSuccess({ samplingEvent }))
    yield call(
      pushRoute,
      `/planning/schools/${samplingEvent.schoolId}/samplingevents/${samplingEvent.id}`
    )

    notify({
      text: ` Sampling event created.`,
      type: NotificationType.Success
    })
  } catch (error) {
    Logger.log(error)
    yield put(createSamplingEventFailure({ error }))
  }
}

function* fetchSchoolSamplingEventSaga({
  payload: { accountId, id, schoolId }
}: FetchSchoolSamplingEventOptions) {
  try {
    // tslint:disable-next-line:max-line-length
    const schoolSamplingEvent: SchoolSamplingEvent = yield call(
      APIProvider.fetchSchoolSchoolSamplingEvent,
      accountId,
      schoolId,
      id
    )
    yield put(fetchSamplingEventSuccess({ schoolSamplingEvent }))
  } catch (error) {
    Logger.log(error)
    yield put(fetchSamplingEventFailure({ error }))
  }
}

// tslint:disable-next-line:max-line-length
function* updateSchoolSamplingEventSaga({
  payload: {
    accountId,
    disableNotification = false,
    redirect,
    samplingEvent,
    schoolId
  }
}: UpdateSamplingEventOptions) {
  try {
    const schoolSamplingEvent: SchoolSamplingEvent = yield call(
      APIProvider.updateSchoolSamplingEvent,
      accountId,
      schoolId,
      samplingEvent
    )
    yield put(updateSamplingEventSuccess({ schoolSamplingEvent }))
    if (redirect) {
      yield call(pushRoute, redirect)
    }

    if (!disableNotification) {
      notify({
        text: `Sampling plan ${samplingEvent.name} updated.`,
        type: NotificationType.Success
      })
    }
  } catch (error) {
    Logger.log(error)
    yield put(updateSamplingEventFailure({ error }))
  }
}

// tslint:disable-next-line:max-line-length
function* deleteSchoolSamplingEventSaga({
  payload: { accountId, samplingEventId, schoolId }
}: DeleteSamplingEventOptions) {
  try {
    // tslint:disable-next-line:max-line-length
    yield call(
      APIProvider.deleteSchoolSamplingEvent,
      accountId,
      schoolId,
      samplingEventId
    )
    yield put(deleteSamplingEventSuccess())
    yield call(pushRoute, `/planning`)

    notify({
      text: `Sampling plan deleted.`,
      type: NotificationType.Success
    })
  } catch (error) {
    Logger.log(error)
    yield put(deleteSamplingEventFailure({ error }))
  }
}

// tslint:disable-next-line:max-line-length
function* updateSchoolSamplingEventStatusSaga({
  payload: {
    accountId,
    collectionCompletedNotes,
    designCompletedNotes,
    samplingEventId,
    schoolId,
    status
  }
}: UpdateSamplingEventStatusOptions) {
  try {
    yield call(
      APIProvider.updateSchoolSamplingEventStatus,
      accountId,
      schoolId,
      samplingEventId,
      status,
      designCompletedNotes,
      collectionCompletedNotes
    )
    // tslint:disable-next-line:max-line-length
    const schoolSamplingEvent: SchoolSamplingEvent = yield call(
      APIProvider.fetchSchoolSchoolSamplingEvent,
      accountId,
      schoolId,
      samplingEventId
    )
    yield put(updateSamplingEventSuccess({ schoolSamplingEvent }))
  } catch (error) {
    Logger.log(error)
    yield put(updateSchoolSamplingEventStatusFailure({ error }))

    if (error && error.response && error.response.data) {
      notify({
        text: error.response.data,
        timeout: false,
        type: NotificationType.Error
      })
    }
  }
}

function* releaseSampleResultsSaga(action: any) {
  try {
    const { accountId, samplingEventId, schoolId } = action.payload
    yield call(APIProvider.releaseSampleResults, {
      accountId,
      samplingEventId,
      schoolId
    })

    const schoolSamplingEvent: SchoolSamplingEvent = yield call(
      APIProvider.fetchSchoolSchoolSamplingEvent,
      accountId,
      schoolId,
      samplingEventId
    )

    yield put(updateSamplingEventSuccess({ schoolSamplingEvent }))

    notify({
      text: `Results released.`,
      type: NotificationType.Success
    })
  } catch (error) {
    Logger.log(error)
    yield put(updateSchoolSamplingEventStatusFailure({ error }))
    if (error && error.response && error.response.data) {
      notify({
        text: error.response.data,
        timeout: false,
        type: NotificationType.Error
      })
    }
  }
}

function* fetchSchoolSamplingEventsSaga(action: any) {
  try {
    const { accountId, schoolId } = action.payload
    const schoolSamplingEvents: SchoolSamplingEvent[] = yield call(
      APIProvider.fetchSchoolSamplingEvents,
      {
        accountId,
        schoolId
      }
    )

    yield put(fetchSchoolSamplingEventsSuccess({ schoolSamplingEvents }))
  } catch (error) {
    Logger.log(error)
    yield put(fetchSchoolSamplingEventsFailure({ error }))
  }
}

export default function* schoolSaga() {
  yield ((takeLatest as unknown) as any)(
    fetchSamplingEvent.toString(),
    fetchSchoolSamplingEventSaga
  )
  yield ((takeLatest as unknown) as any)(
    createSamplingEvent.toString(),
    createSamplingEventSaga
  )
  yield ((takeLatest as unknown) as any)(
    updateSamplingEvent.toString(),
    updateSchoolSamplingEventSaga
  )
  yield ((takeLatest as unknown) as any)(
    deleteSamplingEvent.toString(),
    deleteSchoolSamplingEventSaga
  )
  yield ((takeLatest as unknown) as any)(
    updateSchoolSamplingEventStatus.toString(),
    updateSchoolSamplingEventStatusSaga
  )
  yield takeLatest(releaseSampleResults.toString(), releaseSampleResultsSaga)
  yield takeLatest(
    fetchSchoolSamplingEvents.toString(),
    fetchSchoolSamplingEventsSaga
  )
}
